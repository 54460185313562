import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var LinkMicClientDataChange = function LinkMicClientDataChange() {
  this.lRoomID = 0;
  this.mData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iLinkMicType = 0;
};
LinkMicClientDataChange.prototype._clone = function () {
  return new Nimo.LinkMicClientDataChange();
};
LinkMicClientDataChange.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicClientDataChange.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicClientDataChange.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomID);
  os.writeMap(1, this.mData);
  os.writeInt32(2, this.iLinkMicType);
};
LinkMicClientDataChange.prototype.readFrom = function (is) {
  this.lRoomID = is.readInt64(0, false, this.lRoomID);
  this.mData = is.readMap(1, false, this.mData);
  this.iLinkMicType = is.readInt32(2, false, this.iLinkMicType);
};
Nimo.LinkMicClientDataChange = LinkMicClientDataChange;
export default LinkMicClientDataChange;