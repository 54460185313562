import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var LinkMicStatusChange = function LinkMicStatusChange() {
  this.lRoomID = 0;
  this.iLinkMicType = 0;
  this.iStatus = 0;
  this.mpClientData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
LinkMicStatusChange.prototype._clone = function () {
  return new Nimo.LinkMicStatusChange();
};
LinkMicStatusChange.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicStatusChange.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicStatusChange.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomID);
  os.writeInt32(1, this.iLinkMicType);
  os.writeInt32(2, this.iStatus);
  os.writeMap(3, this.mpClientData);
};
LinkMicStatusChange.prototype.readFrom = function (is) {
  this.lRoomID = is.readInt64(0, false, this.lRoomID);
  this.iLinkMicType = is.readInt32(1, false, this.iLinkMicType);
  this.iStatus = is.readInt32(2, false, this.iStatus);
  this.mpClientData = is.readMap(3, false, this.mpClientData);
};
Nimo.LinkMicStatusChange = LinkMicStatusChange;
export default LinkMicStatusChange;