import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetRoomMcInfoReq = function GetRoomMcInfoReq() {
  this.sUser = new Nimo.UserId();
  this.lRoomId = 0;
};
GetRoomMcInfoReq.prototype._clone = function () {
  return new Nimo.GetRoomMcInfoReq();
};
GetRoomMcInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomMcInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomMcInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.sUser);
  os.writeInt64(1, this.lRoomId);
};
GetRoomMcInfoReq.prototype.readFrom = function (is) {
  this.sUser = is.readStruct(0, false, this.sUser);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
};
Nimo.GetRoomMcInfoReq = GetRoomMcInfoReq;
export default GetRoomMcInfoReq;