import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var UserLinkMicBoxInfo = function UserLinkMicBoxInfo() {
  this.iAppId = 0;
  this.sIconUrl = "";
  this.sBoxUrl_2_3 = "";
  this.sBoxUrl_1_1 = "";
  this.sBoxUrl_4_3 = "";
  this.mNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
UserLinkMicBoxInfo.prototype._clone = function () {
  return new Nimo.UserLinkMicBoxInfo();
};
UserLinkMicBoxInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserLinkMicBoxInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserLinkMicBoxInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iAppId);
  os.writeString(1, this.sIconUrl);
  os.writeString(2, this.sBoxUrl_2_3);
  os.writeString(3, this.sBoxUrl_1_1);
  os.writeString(4, this.sBoxUrl_4_3);
  os.writeMap(5, this.mNameLang);
  os.writeMap(6, this.mExtraData);
};
UserLinkMicBoxInfo.prototype.readFrom = function (is) {
  this.iAppId = is.readInt32(0, false, this.iAppId);
  this.sIconUrl = is.readString(1, false, this.sIconUrl);
  this.sBoxUrl_2_3 = is.readString(2, false, this.sBoxUrl_2_3);
  this.sBoxUrl_1_1 = is.readString(3, false, this.sBoxUrl_1_1);
  this.sBoxUrl_4_3 = is.readString(4, false, this.sBoxUrl_4_3);
  this.mNameLang = is.readMap(5, false, this.mNameLang);
  this.mExtraData = is.readMap(6, false, this.mExtraData);
};
Nimo.UserLinkMicBoxInfo = UserLinkMicBoxInfo;
export default UserLinkMicBoxInfo;