import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var UserRealTimeClipReq = function UserRealTimeClipReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.lRoomID = 0;
  this.iPreview = 1;
  this.iRecordType = 0;
  this.iStartTime = 0;
  this.iEndTime = 0;
  this.lAnchorId = 0;
};
UserRealTimeClipReq.prototype._clone = function () {
  return new NimoBuss.UserRealTimeClipReq();
};
UserRealTimeClipReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserRealTimeClipReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserRealTimeClipReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.lRoomID);
  os.writeInt32(2, this.iPreview);
  os.writeInt32(3, this.iRecordType);
  os.writeInt64(4, this.iStartTime);
  os.writeInt64(5, this.iEndTime);
  os.writeInt64(6, this.lAnchorId);
};
UserRealTimeClipReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.lRoomID = is.readInt64(1, false, this.lRoomID);
  this.iPreview = is.readInt32(2, false, this.iPreview);
  this.iRecordType = is.readInt32(3, false, this.iRecordType);
  this.iStartTime = is.readInt64(4, false, this.iStartTime);
  this.iEndTime = is.readInt64(5, false, this.iEndTime);
  this.lAnchorId = is.readInt64(6, false, this.lAnchorId);
};
NimoBuss.UserRealTimeClipReq = UserRealTimeClipReq;
export default UserRealTimeClipReq;