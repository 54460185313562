// 判断是否有mini播放器
export var checkIsMiniPlayer = function checkIsMiniPlayer() {
  var video = document.querySelector('#mini-player-wrapper video');
  return !!video;
};
// 格式化seiInfo
export var formatSeiInfo = function formatSeiInfo(str) {
  var _posInfo;
  var posInfo = str.split(':')[2];
  posInfo = posInfo.substr(1, ((_posInfo = posInfo) === null || _posInfo === void 0 ? void 0 : _posInfo.length) - 2).split('#').map(function (seatItem) {
    var _seatItem$split;
    // uid, left, top, right, bottom, pos
    var info = seatItem === null || seatItem === void 0 ? void 0 : (_seatItem$split = seatItem.split(',')) === null || _seatItem$split === void 0 ? void 0 : _seatItem$split.map(function (item) {
      return Number(item) || 0;
    });
    var uid = info[0];
    var left = info[1] * 100;
    var top = info[2] * 100;
    var width = info[3] * 100 - left;
    var height = info[4] * 100 - top;
    return {
      uid: uid,
      left: left ? "".concat(left, "%") : 0,
      top: top ? "".concat(top, "%") : 0,
      width: width ? "".concat(width, "%") : 0,
      height: height ? "".concat(height, "%") : 0,
      iIndex: info[5]
    };
  });
  return posInfo;
};