import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LinkMicUserInfo';
var AudienceLinkMicInfo = function AudienceLinkMicInfo() {
  this.iFreeUpStatus = 0;
  this.mData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mUserInfo = new Taf.Map(new Taf.INT32(), new Nimo.LinkMicUserInfo());
};
AudienceLinkMicInfo.prototype._clone = function () {
  return new Nimo.AudienceLinkMicInfo();
};
AudienceLinkMicInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AudienceLinkMicInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AudienceLinkMicInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iFreeUpStatus);
  os.writeMap(1, this.mData);
  os.writeMap(2, this.mUserInfo);
};
AudienceLinkMicInfo.prototype.readFrom = function (is) {
  this.iFreeUpStatus = is.readInt32(0, false, this.iFreeUpStatus);
  this.mData = is.readMap(1, false, this.mData);
  this.mUserInfo = is.readMap(2, false, this.mUserInfo);
};
Nimo.AudienceLinkMicInfo = AudienceLinkMicInfo;
export default AudienceLinkMicInfo;