import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PKRankInfo';
import './UserLinkMicBoxInfo';
var LinkMicUserInfo = function LinkMicUserInfo() {
  this.lUID = 0;
  this.lRoomID = 0;
  this.iGender = 0;
  this.sAvatar = "";
  this.sNickName = "";
  this.sCountryCode = "";
  this.iDeviceType = 0;
  this.iRoyalLevel = 0;
  this.iCallingCode = 0;
  this.lFollowCnt = 0;
  this.iIsFriend = 0;
  this.iAnchorType = 0;
  this.iSourceType = 0;
  this.tPKRankInfo = new Nimo.PKRankInfo();
  this.iPos = 0;
  this.tBoxInfo = new Nimo.UserLinkMicBoxInfo();
  this.iPKWinsNum = 0;
};
LinkMicUserInfo.prototype._clone = function () {
  return new Nimo.LinkMicUserInfo();
};
LinkMicUserInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicUserInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicUserInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeInt64(1, this.lRoomID);
  os.writeInt32(2, this.iGender);
  os.writeString(3, this.sAvatar);
  os.writeString(4, this.sNickName);
  os.writeString(5, this.sCountryCode);
  os.writeInt32(6, this.iDeviceType);
  os.writeInt32(7, this.iRoyalLevel);
  os.writeInt32(8, this.iCallingCode);
  os.writeInt64(9, this.lFollowCnt);
  os.writeInt32(10, this.iIsFriend);
  os.writeInt32(11, this.iAnchorType);
  os.writeInt32(12, this.iSourceType);
  os.writeStruct(13, this.tPKRankInfo);
  os.writeInt32(14, this.iPos);
  os.writeStruct(15, this.tBoxInfo);
  os.writeInt32(16, this.iPKWinsNum);
};
LinkMicUserInfo.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.lRoomID = is.readInt64(1, false, this.lRoomID);
  this.iGender = is.readInt32(2, false, this.iGender);
  this.sAvatar = is.readString(3, false, this.sAvatar);
  this.sNickName = is.readString(4, false, this.sNickName);
  this.sCountryCode = is.readString(5, false, this.sCountryCode);
  this.iDeviceType = is.readInt32(6, false, this.iDeviceType);
  this.iRoyalLevel = is.readInt32(7, false, this.iRoyalLevel);
  this.iCallingCode = is.readInt32(8, false, this.iCallingCode);
  this.lFollowCnt = is.readInt64(9, false, this.lFollowCnt);
  this.iIsFriend = is.readInt32(10, false, this.iIsFriend);
  this.iAnchorType = is.readInt32(11, false, this.iAnchorType);
  this.iSourceType = is.readInt32(12, false, this.iSourceType);
  this.tPKRankInfo = is.readStruct(13, false, this.tPKRankInfo);
  this.iPos = is.readInt32(14, false, this.iPos);
  this.tBoxInfo = is.readStruct(15, false, this.tBoxInfo);
  this.iPKWinsNum = is.readInt32(16, false, this.iPKWinsNum);
};
Nimo.LinkMicUserInfo = LinkMicUserInfo;
export default LinkMicUserInfo;