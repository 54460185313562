import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import confirm from './confirm';
import DashboardButton from '@client/common/components/DashboardButton';
import './index';
import './style/nimo-dashboard-confirm';
export default function dashboardConfirm(props) {
  var config = _objectSpread(_objectSpread({
    prefixCls: 'nimo-dashboard-confirm',
    type: 'confirm',
    width: 510,
    okCancel: true,
    useModalTitle: true,
    primaryButtonFirst: true,
    cancelType: 'secondary-ghost',
    buttonComponent: DashboardButton
  }, props), {}, {
    title: props.title || "\u200B"
  });
  return confirm(config);
}