import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LinkMicListUser';
var LinkMicListInfo = function LinkMicListInfo() {
  this.vUsers = new Taf.Vector(new Nimo.LinkMicListUser());
  this.lRoomID = 0;
};
LinkMicListInfo.prototype._clone = function () {
  return new Nimo.LinkMicListInfo();
};
LinkMicListInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicListInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicListInfo.prototype.writeTo = function (os) {
  os.writeVector(0, this.vUsers);
  os.writeInt64(1, this.lRoomID);
};
LinkMicListInfo.prototype.readFrom = function (is) {
  this.vUsers = is.readVector(0, false, this.vUsers);
  this.lRoomID = is.readInt64(1, false, this.lRoomID);
};
Nimo.LinkMicListInfo = LinkMicListInfo;
export default LinkMicListInfo;