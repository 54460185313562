import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MeetingStatus = function MeetingStatus() {
  this.lAnchorUID = 0;
  this.lRoomID = 0;
  this.iMeetingStatus = 0;
  this.sSessionID = "";
};
MeetingStatus.prototype._clone = function () {
  return new Nimo.MeetingStatus();
};
MeetingStatus.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MeetingStatus.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MeetingStatus.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorUID);
  os.writeInt64(1, this.lRoomID);
  os.writeInt32(2, this.iMeetingStatus);
  os.writeString(3, this.sSessionID);
};
MeetingStatus.prototype.readFrom = function (is) {
  this.lAnchorUID = is.readInt64(0, false, this.lAnchorUID);
  this.lRoomID = is.readInt64(1, false, this.lRoomID);
  this.iMeetingStatus = is.readInt32(2, false, this.iMeetingStatus);
  this.sSessionID = is.readString(3, false, this.sSessionID);
};
Nimo.MeetingStatus = MeetingStatus;
export default MeetingStatus;