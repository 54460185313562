import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetLinkMicListReq = function GetLinkMicListReq() {
  this.tUser = new Nimo.UserId();
  this.lUID = 0;
  this.iNeedOldMCInfo = 0;
};
GetLinkMicListReq.prototype._clone = function () {
  return new Nimo.GetLinkMicListReq();
};
GetLinkMicListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetLinkMicListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetLinkMicListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lUID);
  os.writeInt32(2, this.iNeedOldMCInfo);
};
GetLinkMicListReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lUID = is.readInt64(1, false, this.lUID);
  this.iNeedOldMCInfo = is.readInt32(2, false, this.iNeedOldMCInfo);
};
Nimo.GetLinkMicListReq = GetLinkMicListReq;
export default GetLinkMicListReq;