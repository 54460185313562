import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var InvitaUpMcReq = function InvitaUpMcReq() {
  this.sUser = new Nimo.UserId();
  this.lRoomId = 0;
  this.lUId = 0;
};
InvitaUpMcReq.prototype._clone = function () {
  return new Nimo.InvitaUpMcReq();
};
InvitaUpMcReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
InvitaUpMcReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
InvitaUpMcReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.sUser);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lUId);
};
InvitaUpMcReq.prototype.readFrom = function (is) {
  this.sUser = is.readStruct(0, false, this.sUser);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lUId = is.readInt64(2, false, this.lUId);
};
Nimo.InvitaUpMcReq = InvitaUpMcReq;
export default InvitaUpMcReq;