import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var ReportShareReq = function ReportShareReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
  this.iChannelType = 0;
  this.iOriginId = 0;
};
ReportShareReq.prototype._clone = function () {
  return new Nimo.ReportShareReq();
};
ReportShareReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ReportShareReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ReportShareReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
  os.writeInt32(2, this.iChannelType);
  os.writeInt32(3, this.iOriginId);
};
ReportShareReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.iChannelType = is.readInt32(2, false, this.iChannelType);
  this.iOriginId = is.readInt32(3, false, this.iOriginId);
};
Nimo.ReportShareReq = ReportShareReq;
export default ReportShareReq;