import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var PublishUserClipReq = function PublishUserClipReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.lAnchorId = 0;
  this.videoId = "";
  this.title = "";
  this.syncToPost = 0;
  this.hashTagIds = "";
  this.customTagIds = "";
};
PublishUserClipReq.prototype._clone = function () {
  return new NimoBuss.PublishUserClipReq();
};
PublishUserClipReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PublishUserClipReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PublishUserClipReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.lAnchorId);
  os.writeString(2, this.videoId);
  os.writeString(3, this.title);
  os.writeInt32(4, this.syncToPost);
  os.writeString(5, this.hashTagIds);
  os.writeString(6, this.customTagIds);
};
PublishUserClipReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.lAnchorId = is.readInt64(1, false, this.lAnchorId);
  this.videoId = is.readString(2, false, this.videoId);
  this.title = is.readString(3, false, this.title);
  this.syncToPost = is.readInt32(4, false, this.syncToPost);
  this.hashTagIds = is.readString(5, false, this.hashTagIds);
  this.customTagIds = is.readString(6, false, this.customTagIds);
};
NimoBuss.PublishUserClipReq = PublishUserClipReq;
export default PublishUserClipReq;