import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LinkMicListInfo';
import './GetRoomMcInfoRsp';
import './AudienceLinkMicInfo';
import './LinkMicPosInfo';
var GetLinkMicListRsp = function GetLinkMicListRsp() {
  this.iCode = 0;
  this.sMessage = "";
  this.tInfo = new Nimo.LinkMicListInfo();
  this.tMCInfo = new Nimo.GetRoomMcInfoRsp();
  this.iLinkMicType = 0;
  this.tAudienceInfo = new Nimo.AudienceLinkMicInfo();
  this.tPosInfo = new Nimo.LinkMicPosInfo();
  this.mpClientData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
GetLinkMicListRsp.prototype._clone = function () {
  return new Nimo.GetLinkMicListRsp();
};
GetLinkMicListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetLinkMicListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetLinkMicListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sMessage);
  os.writeStruct(2, this.tInfo);
  os.writeStruct(3, this.tMCInfo);
  os.writeInt32(4, this.iLinkMicType);
  os.writeStruct(5, this.tAudienceInfo);
  os.writeStruct(6, this.tPosInfo);
  os.writeMap(7, this.mpClientData);
};
GetLinkMicListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sMessage = is.readString(1, false, this.sMessage);
  this.tInfo = is.readStruct(2, false, this.tInfo);
  this.tMCInfo = is.readStruct(3, false, this.tMCInfo);
  this.iLinkMicType = is.readInt32(4, false, this.iLinkMicType);
  this.tAudienceInfo = is.readStruct(5, false, this.tAudienceInfo);
  this.tPosInfo = is.readStruct(6, false, this.tPosInfo);
  this.mpClientData = is.readMap(7, false, this.mpClientData);
};
Nimo.GetLinkMicListRsp = GetLinkMicListRsp;
export default GetLinkMicListRsp;