import { getLang } from './cookieFn';

/**
 * 获取游戏品类的跳转路径名
 *
 * 优先级 ： shortGameName > overrideKey(若已传入且存在) > id
 *
 * @param {object} item 游戏信息
 * @param {string} overrideKey 取不到游戏简称时，需要取特定key的值，比如首页的热门游戏的gameId和id不是同一个概念，就需要指定取gameId
 *
 * @returns {string} SEOGameName
 */
export var getSEOGameName = function getSEOGameName() {
  var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var overrideKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var urlLang = 1033;
  var gameName = '';
  if (item !== null && item !== void 0 && item.gameAbbrList) {
    var _gameName;
    var gameAbbrList = [].concat(item === null || item === void 0 ? void 0 : item.gameAbbrList);
    gameName = gameAbbrList === null || gameAbbrList === void 0 ? void 0 : gameAbbrList.find(function (langItem) {
      return (langItem === null || langItem === void 0 ? void 0 : langItem.langId) == urlLang;
    });
    gameName = (_gameName = gameName) === null || _gameName === void 0 ? void 0 : _gameName.value;
  }
  if (!gameName && overrideKey) {
    gameName = item === null || item === void 0 ? void 0 : item[overrideKey];
  }
  if (!gameName) {
    gameName = item === null || item === void 0 ? void 0 : item.id;
  }
  return encodeURIComponent(gameName || '') || '';
};

/**
 * 获取游戏品类的显示名称（目前仅在少部分地方用到）
 * 优先级：游戏简称多语言>游戏简称英文>游戏名称多语言>游戏名称英文
 * @param {object} item
 * @returns {string} DisplayGameName
 */
export var getDisplayGameName = function getDisplayGameName() {
  var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultLang = 1033;
  var lang = getLang();
  var gameName = '';
  if (item !== null && item !== void 0 && item.gameAbbrList) {
    var _gameName2;
    var gameAbbrList = [].concat(item === null || item === void 0 ? void 0 : item.gameAbbrList);
    gameName = gameAbbrList === null || gameAbbrList === void 0 ? void 0 : gameAbbrList.find(function (langItem) {
      return (langItem === null || langItem === void 0 ? void 0 : langItem.langId) == lang;
    });
    gameName = (_gameName2 = gameName) === null || _gameName2 === void 0 ? void 0 : _gameName2.value;
    if (!gameName) {
      var _gameName3;
      gameName = gameAbbrList === null || gameAbbrList === void 0 ? void 0 : gameAbbrList.find(function (langItem) {
        return (langItem === null || langItem === void 0 ? void 0 : langItem.langId) == defaultLang;
      });
      gameName = (_gameName3 = gameName) === null || _gameName3 === void 0 ? void 0 : _gameName3.value;
    }
  }
  if (!gameName && item !== null && item !== void 0 && item.nameLang) {
    var _nameLang, _gameName4;
    var nameLang = (item === null || item === void 0 ? void 0 : item.nameLang) || [];
    try {
      nameLang = JSON.parse(item === null || item === void 0 ? void 0 : item.nameLang);
    } catch (error) {}
    gameName = (_nameLang = nameLang) === null || _nameLang === void 0 ? void 0 : _nameLang.find(function (langItem) {
      return (langItem === null || langItem === void 0 ? void 0 : langItem.langId) == lang;
    });
    gameName = (_gameName4 = gameName) === null || _gameName4 === void 0 ? void 0 : _gameName4.value;
    if (!gameName) {
      var _nameLang2, _gameName5;
      gameName = (_nameLang2 = nameLang) === null || _nameLang2 === void 0 ? void 0 : _nameLang2.find(function (langItem) {
        return (langItem === null || langItem === void 0 ? void 0 : langItem.langId) == defaultLang;
      });
      gameName = (_gameName5 = gameName) === null || _gameName5 === void 0 ? void 0 : _gameName5.value;
    }
  }
  return gameName || '';
};
export var getGameTypeSearch = function getGameTypeSearch(type) {
  // eslint-disable-next-line no-param-reassign
  type = typeof type === 'string' ? type.toLowerCase() : '';
  if (!type || type === 'all') return '';
  return "?gameType=".concat(type);
};
export var normalizeGameType = function normalizeGameType(type) {
  return type && ['all', 'others', 'mobile', 'pc'].indexOf(type) !== -1 ? type : 'all';
};