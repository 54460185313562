import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var UpdateTitleReq = function UpdateTitleReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.lAuthorId = 0;
  this.videoId = "";
  this.title = "";
};
UpdateTitleReq.prototype._clone = function () {
  return new NimoBuss.UpdateTitleReq();
};
UpdateTitleReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UpdateTitleReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UpdateTitleReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.lAuthorId);
  os.writeString(2, this.videoId);
  os.writeString(3, this.title);
};
UpdateTitleReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.lAuthorId = is.readInt64(1, false, this.lAuthorId);
  this.videoId = is.readString(2, false, this.videoId);
  this.title = is.readString(3, false, this.title);
};
NimoBuss.UpdateTitleReq = UpdateTitleReq;
export default UpdateTitleReq;