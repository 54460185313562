import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var ShortChainRsp = function ShortChainRsp() {
  this.shortChain = "";
};
ShortChainRsp.prototype._clone = function () {
  return new NimoBuss.ShortChainRsp();
};
ShortChainRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShortChainRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShortChainRsp.prototype.writeTo = function (os) {
  os.writeString(0, this.shortChain);
};
ShortChainRsp.prototype.readFrom = function (is) {
  this.shortChain = is.readString(0, false, this.shortChain);
};
NimoBuss.ShortChainRsp = ShortChainRsp;
export default ShortChainRsp;