import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var VideoItemView = function VideoItemView() {
  this.resolution = "";
  this.videoUrl = "";
};
VideoItemView.prototype._clone = function () {
  return new NimoBuss.VideoItemView();
};
VideoItemView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VideoItemView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VideoItemView.prototype.writeTo = function (os) {
  os.writeString(0, this.resolution);
  os.writeString(1, this.videoUrl);
};
VideoItemView.prototype.readFrom = function (is) {
  this.resolution = is.readString(0, false, this.resolution);
  this.videoUrl = is.readString(1, false, this.videoUrl);
};
NimoBuss.VideoItemView = VideoItemView;
export default VideoItemView;