import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetMeetingInfoReq = function GetMeetingInfoReq() {
  this.tUser = new Nimo.UserId();
  this.lAnchorUID = 0;
};
GetMeetingInfoReq.prototype._clone = function () {
  return new Nimo.GetMeetingInfoReq();
};
GetMeetingInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMeetingInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMeetingInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lAnchorUID);
};
GetMeetingInfoReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lAnchorUID = is.readInt64(1, false, this.lAnchorUID);
};
Nimo.GetMeetingInfoReq = GetMeetingInfoReq;
export default GetMeetingInfoReq;