import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './ShowMicRect';
var ShowMicBox = function ShowMicBox() {
  this.tRectTop = new Nimo.ShowMicRect();
  this.tRectV = new Nimo.ShowMicRect();
  this.tRectA = new Nimo.ShowMicRect();
};
ShowMicBox.prototype._clone = function () {
  return new Nimo.ShowMicBox();
};
ShowMicBox.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShowMicBox.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShowMicBox.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tRectTop);
  os.writeStruct(1, this.tRectV);
  os.writeStruct(2, this.tRectA);
};
ShowMicBox.prototype.readFrom = function (is) {
  this.tRectTop = is.readStruct(0, false, this.tRectTop);
  this.tRectV = is.readStruct(1, false, this.tRectV);
  this.tRectA = is.readStruct(2, false, this.tRectA);
};
Nimo.ShowMicBox = ShowMicBox;
export default ShowMicBox;