import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LinkMicUserInfo';
var LinkMicPosInfo = function LinkMicPosInfo() {
  this.mUserInfo = new Taf.Map(new Taf.INT32(), new Nimo.LinkMicUserInfo());
};
LinkMicPosInfo.prototype._clone = function () {
  return new Nimo.LinkMicPosInfo();
};
LinkMicPosInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LinkMicPosInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LinkMicPosInfo.prototype.writeTo = function (os) {
  os.writeMap(0, this.mUserInfo);
};
LinkMicPosInfo.prototype.readFrom = function (is) {
  this.mUserInfo = is.readMap(0, false, this.mUserInfo);
};
Nimo.LinkMicPosInfo = LinkMicPosInfo;
export default LinkMicPosInfo;