import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var UserClipReq = function UserClipReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.lAuthorId = 0;
  this.opsType = 0;
  this.lAnchorId = 0;
};
UserClipReq.prototype._clone = function () {
  return new NimoBuss.UserClipReq();
};
UserClipReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserClipReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserClipReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.lAuthorId);
  os.writeInt32(2, this.opsType);
  os.writeInt64(3, this.lAnchorId);
};
UserClipReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.lAuthorId = is.readInt64(1, false, this.lAuthorId);
  this.opsType = is.readInt32(2, false, this.opsType);
  this.lAnchorId = is.readInt64(3, false, this.lAnchorId);
};
NimoBuss.UserClipReq = UserClipReq;
export default UserClipReq;