import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './VoiceRoomEmojiConfig';
var VoiceRoomEmojiNotice = function VoiceRoomEmojiNotice() {
  this.lUid = 0;
  this.lRoomId = 0;
  this.tConfig = new Nimo.VoiceRoomEmojiConfig();
};
VoiceRoomEmojiNotice.prototype._clone = function () {
  return new Nimo.VoiceRoomEmojiNotice();
};
VoiceRoomEmojiNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VoiceRoomEmojiNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VoiceRoomEmojiNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt64(4, this.lRoomId);
  os.writeStruct(5, this.tConfig);
};
VoiceRoomEmojiNotice.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.lRoomId = is.readInt64(4, false, this.lRoomId);
  this.tConfig = is.readStruct(5, false, this.tConfig);
};
Nimo.VoiceRoomEmojiNotice = VoiceRoomEmojiNotice;
export default VoiceRoomEmojiNotice;