import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AVPostBizData';
var AVPostMediaRscInfo = function AVPostMediaRscInfo() {
  this.sRscID = "";
  this.iMediaType = 0;
  this.sURL = "";
  this.sFormat = "";
  this.iDuration = 0;
  this.lSize = 0;
  this.iWidth = 0;
  this.iHeight = 0;
  this.iResolution = 0;
  this.iBitRate = 0;
  this.iRecordType = 0;
  this.tBizData = new Nimo.AVPostBizData();
};
AVPostMediaRscInfo.prototype._clone = function () {
  return new Nimo.AVPostMediaRscInfo();
};
AVPostMediaRscInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AVPostMediaRscInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AVPostMediaRscInfo.prototype.writeTo = function (os) {
  os.writeString(0, this.sRscID);
  os.writeInt32(1, this.iMediaType);
  os.writeString(2, this.sURL);
  os.writeString(3, this.sFormat);
  os.writeInt32(4, this.iDuration);
  os.writeInt64(5, this.lSize);
  os.writeInt32(6, this.iWidth);
  os.writeInt32(7, this.iHeight);
  os.writeInt32(8, this.iResolution);
  os.writeInt32(9, this.iBitRate);
  os.writeInt32(10, this.iRecordType);
  os.writeStruct(11, this.tBizData);
};
AVPostMediaRscInfo.prototype.readFrom = function (is) {
  this.sRscID = is.readString(0, false, this.sRscID);
  this.iMediaType = is.readInt32(1, false, this.iMediaType);
  this.sURL = is.readString(2, false, this.sURL);
  this.sFormat = is.readString(3, false, this.sFormat);
  this.iDuration = is.readInt32(4, false, this.iDuration);
  this.lSize = is.readInt64(5, false, this.lSize);
  this.iWidth = is.readInt32(6, false, this.iWidth);
  this.iHeight = is.readInt32(7, false, this.iHeight);
  this.iResolution = is.readInt32(8, false, this.iResolution);
  this.iBitRate = is.readInt32(9, false, this.iBitRate);
  this.iRecordType = is.readInt32(10, false, this.iRecordType);
  this.tBizData = is.readStruct(11, false, this.tBizData);
};
Nimo.AVPostMediaRscInfo = AVPostMediaRscInfo;
export default AVPostMediaRscInfo;