import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ShowMicRect = function ShowMicRect() {
  this.fX = 0;
  this.fY = 0;
  this.fW = 0;
  this.fH = 0;
};
ShowMicRect.prototype._clone = function () {
  return new Nimo.ShowMicRect();
};
ShowMicRect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShowMicRect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShowMicRect.prototype.writeTo = function (os) {
  os.writeFloat(0, this.fX);
  os.writeFloat(1, this.fY);
  os.writeFloat(2, this.fW);
  os.writeFloat(3, this.fH);
};
ShowMicRect.prototype.readFrom = function (is) {
  this.fX = is.readFloat(0, false, this.fX);
  this.fY = is.readFloat(1, false, this.fY);
  this.fW = is.readFloat(2, false, this.fW);
  this.fH = is.readFloat(3, false, this.fH);
};
Nimo.ShowMicRect = ShowMicRect;
export default ShowMicRect;