import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ELinkMicType = {
  kLINKMIC_AUDIENCE: 1,
  kLINKMIC_PRESENTER: 2,
  kLINKMIC_PRESENTER_NEW: 3,
  kLINKMIC_MULTI: 4,
  kLINKMIC_AUDIENCE_NEW: 5,
  kLINKMIC_TEAM: 6
};
Nimo.ELinkMicType = ELinkMicType;
export default ELinkMicType;